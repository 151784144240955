import { Link } from 'gatsby'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import AppResize from '~/components/AppResize'
import GlobalStyle from '~/components/GlobalStyle'
import { useAppContext } from '~/contexts/appContext'
import { headerFontFamilyString } from '~/utils/styling'
import AppBar from './AppBar'
import ContentView from './ContentView'
import Footer from './Footer'
import More from './More'
import NavBar from './NavBar'
import RootContainer from './RootContainer'

const Menu = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  font-family: ${headerFontFamilyString};
  width: 100%;
  padding-right: 1rem;
`

const HMenu = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  font-family: ${headerFontFamilyString};
  width: 100%;
  align-items: center;
  flex-wrap: nowrap;
`

const MenuItem = styled.li`
  margin-top: 2px;
  flex: 0 1 auto;
  color: rgba(255, 255, 255, 0.75);
  margin-left: 1rem;
  font-size: 1rem;
  line-height: 48px;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  a {
    color: rgba(255, 255, 255, 0.75);
    text-decoration: none;
  }
  a:link {
    color: rgba(255, 255, 255, 0.75);
    text-decoration: none;
  }
  a:hover {
    color: rgba(255, 255, 255, 0.95);
    text-shadow: 0 0 3px rgba(255, 255, 255, 0.3);
    text-decoration: none;
  }
  a:visited {
    color: rgba(255, 255, 255, 0.75);
    text-decoration: none;
  }
  a:active,
  .active {
    color: rgba(0, 0, 0, 0.4);
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.08);
  }
  &.white {
    color: rgba(255, 255, 255, 0.85);
  }
  hr {
    position: relative;
    top: 8px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    width: 200px;
    height: 2px;
    background: rgba(0, 0, 0, 0.45);
  }
`

const Subtitle = styled.li`
  margin-top: 2px;
  flex: 0 1 auto;
  color: rgba(209, 162, 255, 0.75);
  font-weight: 700;
  /* color: rgba(0, 0, 0, .8); */
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.08);
  margin-left: 1rem;
  font-size: 0.75rem;
  line-height: 48px;
  text-transform: uppercase;
`

const Space = styled.li`
  flex: 1;
`

const Main = styled.div`
  flex: 1;
  min-height: calc(100vh - 240px);
`

interface ILayoutProps {
  uri?: string
}

const Layout: React.FC<ILayoutProps> = ({ uri, children }) => {
  const { state, dispatch } = useAppContext()

  uri = uri || ''

  let leftMenuItem = (
    <MenuItem>
      <Link to="/">← Главная</Link>
    </MenuItem>
  )

  if (uri === '/') {
    leftMenuItem = <MenuItem></MenuItem>
  } else if (uri === `/blog` || /^\/blog\/page-[0-9]{1,2}\/?$/.test(uri)) {
    leftMenuItem = (
      <MenuItem>
        <Link to="/">← Главная</Link>
      </MenuItem>
    )
  } else if (/^\/blog\/.+/.test(uri)) {
    leftMenuItem = (
      <MenuItem>
        <Link to="/blog">← Назад</Link>
      </MenuItem>
    )
  } else if (/^\/book\/.+/.test(uri)) {
    leftMenuItem = (
      <MenuItem>
        <Link to="/books">← Назад</Link>
      </MenuItem>
    )
  }

  const about =
    uri === `/about` ? (
      <span className="active">Обо мне</span>
    ) : (
      <Link to="/about">Обо мне</Link>
    )

  const blog =
    uri === `/blog` ? (
      <span className="active">Блог</span>
    ) : (
      <Link to="/blog">Блог</Link>
    )

  const books =
    uri === `/books` ? (
      <span className="active">Книги</span>
    ) : (
      <Link to="/books">Книги</Link>
    )

  function onMoreClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    dispatch({ type: `TOGGLE_DRAWER` })
    e.preventDefault()
  }

  const more = (
    <a href="#" onClick={onMoreClick}>
      <More active={state.drawer}></More>
    </a>
  )

  useEffect(() => {
    const YT_LINK_REX = /https:\/\/www\.youtube\.com\/watch\?v=(.+?)&t=(\d+)s/
    function interceptClickEvent(e: MouseEvent) {
      if (!e) return
      if (!e.target) return
      const target = e.target as any
      if (target.tagName !== 'A') return
      if (!window._player) return
      const player = window._player as any
      const href = target.getAttribute('href')
      const m = YT_LINK_REX.exec(href)
      if (!m || !m[1] || !m[2]) return
      const [, id, t] = m
      if (!player[id]) return
      e.preventDefault()
      player[id].seekTo(parseInt(t, 10))
      player[id].playVideo()
    }
    document.addEventListener('click', interceptClickEvent)
    return () => {
      document.removeEventListener('click', interceptClickEvent)
    }
  }, [])

  return (
    <>
      <GlobalStyle></GlobalStyle>
      <NavBar>
        <HMenu>
          {state.pageWidth < 414 && (
            <>
              <MenuItem key="books">{books}</MenuItem>
              <MenuItem key="hr">
                <hr />
              </MenuItem>
            </>
          )}
          <MenuItem key="yt-1">
            <a
              rel="nofollow"
              href="https://www.youtube.com/channel/UCHSRxd7TrrchkxaSsO2HzsQ"
            >
              Проповеди (YouTube) →
            </a>
          </MenuItem>
          <MenuItem key="rt-1">
            <a rel="nofollow" href="https://rutube.ru/channel/39210521">
              Проповеди (RUTUBE) →
            </a>
          </MenuItem>
          <MenuItem key="rt-2">
            <a rel="nofollow" href="https://rutube.ru/channel/43660842/">
              Канал сайта (RUTUBE) →
            </a>
          </MenuItem>
          <MenuItem key="tg">
            <a rel="nofollow" href="https://t.me/slovesnoeprinoshenie">
              Телеграм канал →
            </a>
          </MenuItem>
          <MenuItem key="vk">
            <a rel="nofollow" href="https://vk.com/batushkainfo">
              ВКонтакте →
            </a>
          </MenuItem>
          <MenuItem key="soyuz">
            <a
              rel="nofollow"
              href="https://tv-soyuz.ru/persons/artemiy-vladimirov"
            >
              Телеканал «Союз» →
            </a>
          </MenuItem>
        </HMenu>
      </NavBar>
      <RootContainer>
        <AppBar>
          <Menu>
            {leftMenuItem}
            <Space></Space>
            <MenuItem>{about}</MenuItem>
            <MenuItem>{blog}</MenuItem>
            {state.pageWidth >= 414 && <MenuItem>{books}</MenuItem>}
            <MenuItem>{more}</MenuItem>
          </Menu>
        </AppBar>
        <ContentView>
          <Main>{children}</Main>
          <Footer />
        </ContentView>
      </RootContainer>
      <AppResize></AppResize>
    </>
  )
}

export default Layout
